import Vue from 'vue';
import VueRouter from 'vue-router';
// import Main from '@/views/SmartMain.vue';

Vue.use(VueRouter);

export default new VueRouter({
	mode:'history', //해쉬값 제거 방식
  routes: [
    {
      path: '/',
      name: 'SmartMain',
      component: () => import('../views/SmartMain.vue'),
    },
    {
      path: '/terms',
      component: () => import('../views/SmartTerms.vue'),
    },
    {
      path: '/applink',
      name: 'Applink',
      component: () => import('../views/AppLink.vue'),
    },
    {
      path: '/smart-customer',
      name: 'SmartCustomer',
      component: () => import('../views/SmartMain.vue'),
    },
    {
      path: '/faq/:idx?',
      name: 'Faq',
      component: () => import('../views/SmartMain.vue'),
    },
    {
      path: '/notice/:idx?',
      name: 'Notice',
      component: () => import('../views/SmartMain.vue'),
    },
    {
      // 고도화 이전 약관 상세 바로가기 링크로 접속시 (https://www.sktsmarthome.com/html/terms_history.html?idx=2)
      path: '/html/terms_history.html',
      name: 'Notice',
      component: () => import('../views/SmartMain.vue'),
    },
    {
      path: '/tworld',
      name: 'TworldMain',
      component: () => import('../views/TworldMain.vue'),
    },
  ]
});