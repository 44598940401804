import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import VueGtag from 'vue-gtag'
import { store } from './store/index'

Vue.config.productionTip = false

Vue.use(VueGtag, {
  // config: { id: 'G-MDX4JHQB06' }, // 스테이지
  config: { id: 'G-SFJL0JFCXE' }, // 상용
},router)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
